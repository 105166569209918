<template>
  <div class="row mt-1">
    <div class="col-12">
      <div>
        <div class="card-header p-half bg-light-primary">
          <h6 class="m-0">
            Notes
          </h6>
          <div id="trialRemaining" class="pulse">
              <p-icon
                name="bi-exclamation-circle-fill"
              />
            </div>
        </div>
        <div class="text-warning border rounded-bottom px-half py-half">
          {{ currentOrder.commentStr }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import useFbmState from '../useFbmStates'

export default {
  setup() {
    const { currentOrder } = useFbmState()
    const message = ref('')
    message.value = currentOrder.commentStr
    return {
      message,
      currentOrder,
    }
  },
}
</script>

<style lang="scss" scoped>
.pulse {
      animation: pulse 1s infinite;
      padding-left: 4px;
      color: orange;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
</style>
